export const formatCompanyName = value => value ? `${value}`.replace(/[\W_]+/g, '').toLowerCase() : value;

export const getDomainNameFromString = value => {
    const getDomain = value => new URL(value).host?.replaceAll('www.', '');

    try {
        try {
            return getDomain(value);
        } catch (e) {
            return getDomain(`https://${value}`);
        }
    } catch (e) {
        return '';
    }
};

export const getRandomItem = arr => {
    const randomIndex = Math.floor(Math.random() * arr.length);

    return arr[randomIndex];
};

export const getRandomTagColor = () => {
    const hero = '#54AD95';

    const colors = [
        hero,
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#16a085',
        '#27ae60',
        '#2980b9',
        '#8e44ad',
        '#2c3e50',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
    ];

    return getRandomItem(colors) || hero;
};

export const joinWithAnd = strings => {
    if (strings?.length === 0) {
        return '';
    } else if (strings?.length === 1) {
        return strings[0];
    } else {
        const lastString = strings?.pop();
        return strings ? strings?.join(', ') + ' and ' + lastString : '';
    }
};

export const alphabeticalSorter = (a, b, key = 'name') => {
    if (a[key] < b[key]) {
        return -1;
    }
    if (a[key] > b[key]) {
        return 1;
    }
    return 0;
};

export const filterInData = (data, value, key, sortBy) => {
    const returnValue = (value && Array.isArray(data)) ? data.filter(row => (key ? row[key].toLowerCase() : JSON.stringify(Object.values(row) || {})).toLowerCase().includes(value.toLowerCase())) : data;

    return Array.isArray(returnValue) && sortBy ? returnValue.sort(alphabeticalSorter) : returnValue;
};

export const removeDuplicatesFromArray = arr => {
    if (!Array.isArray(arr)) {
        return [];
    }
    const set = new Set(arr.map(val => JSON.stringify(val)));

    return Array.from(set).map(val => val ? JSON.parse(val) : undefined);
};

export const getFileNameFromSource = val => val ? `${val}`?.split('=').pop() : '';

export const emailValid = str => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return str && regex.test(str);
};

export const getProfilePhoto = profile => {
    const photo = typeof profile === 'string' ? profile : profile?.data?.profilePhoto;

    if (photo && photo.toLowerCase() !== 'withoutphoto.png') {
        return `https://pindropbackup.blob.core.windows.net/img/${photo}`;
    } else {
        return '';
    }
};

export const getOrganisationLogo = (id, logo) => (id && logo) ? `https://pindropbackup.blob.core.windows.net/${id}/${logo}` : '';

export const kFormatter = num => num ? (Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)) : 0;

export const capitalizeFirstLetter = value => typeof value === 'string' ? `${value[0]?.toUpperCase()}${value?.slice(1).toLowerCase()}` : value;

export const titleCase = s => s ? `${s}`.replace(/^_*(.)|_+(.)/gi, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase()).split(' ').map(capitalizeFirstLetter).join(' ') : s;

export const hash = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);

export const guid = () => hash() + hash() + '-' + hash() + '-' + hash() + '-' + hash() + '-' + hash() + hash() + hash();

export const convertStringToHTML = htmlString => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, 'text/html');

    return html.body;
};

export const findDeep = (data, indexStack, index = 0) => (index < indexStack.length ? findDeep(data[indexStack[index]].children, indexStack, index + 1) : data);

export const blobToFile = (theBlob, name) => {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = name;
    return theBlob;
};

export const routeModes = {
    auto: {
        label: 'Driving',
        icon: 'steering-wheel',
    },
    pedestrian: {
        label: 'Walking',
        icon: 'person-walking',
    },
    bicycle: {
        icon: 'bicycle',
        label: 'Cycling',
    },
};

export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = error => reject(error);
    reader.onload = () => resolve(reader.result);
});

export const isScrollable = element => {
    const hasScrollableContent = element.scrollHeight > element.clientHeight;

    const overflowYStyle = window.getComputedStyle(element).overflowY;
    const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

    return hasScrollableContent && !isOverflowHidden;
};

export const getScrollableParent = element => !element || element === document.body ? document.body : isScrollable(element) ? element : getScrollableParent(element.parentNode);

export const getTaskStatusColor = status => status === 'Pending' ? '#FF5D55' : status === 'In Progress' ? '#54AD95' : status === 'Completed' ? '#2D9CDB' : 'gray';

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
